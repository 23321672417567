import { Injectable } from '@angular/core';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  /**
   * @description Armazenar informação no LocalStorage
   *
   * @author Alysson Cordeiro
   *
   * @param key - Chave
   * @param value  - Valor que será armazenado
   */
  storage(key: SessionStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }


 /**
   * @description Recuperar item do LocalStorage
   *
   * @author Alysson Cordeiro
   *
   * @param key - Chave do item
   */
  getItem(key: SessionStorageKey): string {
   return localStorage.getItem(key);
  }

  /**
   * @description Limpar todo o conteúdo do LocalStorage
   *
   * @author Alysson Cordeiro
   */
  clear(): void {
    localStorage.clear();
  }

}
