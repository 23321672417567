<div class="alert-box">
  <div class="modal-header">
      <span *ngIf="icon" ><img [src]="icon"></span>
      <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
      {{message}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn confirm" [ngClass]="customClassConfirm" (click)="onConfirm()" onclick="document.getElementsByClassName('speed-actions__full')[0].style.display='flex'">{{btnConfirm}}</button>
    <button type="button" class="btn cancel" [ngClass]="customClassCancel" (click)="onCancel()" onclick="document.getElementsByClassName('speed-actions__full')[0].style.display='flex'">{{btnCancel}}</button>
  </div>
</div>
