import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PrinterConfiguration } from 'src/app/modules/private/modules/dashboard/components/manage-settings/models/printer-configuration.model';
import { OrderStatusEnum } from 'src/app/modules/private/modules/dashboard/components/orders/enums/order-status.enum';
import { ProductAndQuantity } from 'src/app/modules/private/modules/dashboard/components/orders/models/new-order.model';
import { OrderResponseApiModel } from 'src/app/modules/private/modules/dashboard/components/orders/models/order-response-api.model';
import { PrintService } from 'src/app/modules/private/modules/dashboard/components/orders/services/print.service';

@Component({
  selector: 'app-card-order',
  templateUrl: './card-order.component.html',
  styleUrls: ['./card-order.component.css'],
})
export class CardOrderComponent implements OnInit, OnDestroy {
  @Input() public order: OrderResponseApiModel;
  @Input() public events: Observable<void>;
  @Input() userId: string;
  public eventsSubscription: Subscription;
  public checked: boolean = false;

  @Output() active = new EventEmitter();
  @Output() disabled = new EventEmitter();

  public printConfig: PrinterConfiguration | null = null;

  constructor(
    private _elementRef: ElementRef,
    private printService: PrintService
  ) {}

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((state) => {
      this.toggleCheckbox(state);
    });
    this.getPrinterConfig();
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  /**
   * @description Definir classe de cores do status do pedido
   *
   * @author Darllinson Azevedo
   *
   * @param status Status do pedido
   * @returns Valor da classe
   */
  getTrackingClass(status: OrderStatusEnum): string {
    switch (status) {
      case OrderStatusEnum.PREPARING:
        return 'preparing';
      case OrderStatusEnum.READ_DELIVERY:
        return 'ready';
      case OrderStatusEnum.OUT_DELIVERY:
        return 'delivery';
      case OrderStatusEnum.DELIVERY:
        return 'deliveredandwithdrawn';
      case OrderStatusEnum.CANCELLED:
        return 'notaccepted';
      case OrderStatusEnum.CANCELLED_AUTOMATIC:
        return 'notaccepted';
      case OrderStatusEnum.RECUSED:
        return 'storerefused';
      case OrderStatusEnum.RECEIVED:
        return 'sent';
      case OrderStatusEnum.NEW:
        return 'received';
      case OrderStatusEnum.ACCEPTED:
        return 'approved';
      case OrderStatusEnum.ON_HOLD:
        return 'onhold';
    }
  }

  /**
   * @description Recuperar configuração de impressão do usuário
   *
   * @author Darllinson Azevedo
   */
  getPrinterConfig() {
    this.printService
      .getPrinterConfig()
      .pipe(
        take(1),
        tap((printerConfig) => {
          this.printConfig = printerConfig;
        })
      )
      .subscribe();
  }

  /**
   * @description Imprimir dados do pedido
   *
   * @author Darllinson Azevedo
   */
  printOrder(): void {
    this.printService.printOrder(this.order, 'kitchen');
  }

  /**
   * @description Recuperar valores do enum dos status do pedido
   *
   * @author Darllinson Azevedo
   *
   * @returns Valores do enum dos status do pedido
   */
  public get orderStatus(): typeof OrderStatusEnum {
    return OrderStatusEnum;
  }

  /**
   * @description Calcular preço do produto mais complementos
   *
   * @author Darllinson Azevedo
   *
   * @param product Produto da compra
   */
  calcProductPriceWithComplements(product: ProductAndQuantity): number {
    if (product.product.productComplements) {
      const complements = product.product.productComplements;
      let totalComplements = 0;

      complements.forEach((complement) => {
        complement.options.forEach((option) => {
          const optionPrice = option.option.price || 0;
          totalComplements += optionPrice * option.quantity;
        });
      });

      return Number((product.product.price + totalComplements).toFixed(2));
    }

    return Number(product.product.price.toFixed(2));
  }

  /**
   * @description Calcular preço total do produto mais complementos * quantidade
   *
   * @author Darllinson Azevedo
   *
   * @param product Produto da compra
   * @param quantity Quantidade do produto
   */
  calcProductPriceWithComplementsWithQty(
    product: ProductAndQuantity,
    quantity: number
  ): number {
    if (product.product.productComplements) {
      const complements = product.product.productComplements;
      let totalComplements = 0;

      complements.forEach((complement) => {
        complement.options.forEach((option) => {
          const optionPrice = option.option.price || 0;
          totalComplements += optionPrice * option.quantity;
        });
      });

      return Number(
        (
          product.product.price * quantity +
          totalComplements * quantity
        ).toFixed(2)
      );
    }

    return Number((product.product.price * quantity).toFixed(2));
  }

  toggleCheckbox(state = null) {
    if (state !== null) {
      this.checked = state;
    } else {
      this.checked = !this.checked;
    }

    if (this.checked) {
      this._elementRef.nativeElement
        .querySelector('.card__container')
        .classList.add('card__container--active');

      this.active.emit(this.order);
    } else {
      this._elementRef.nativeElement
        .querySelector('.card__container')
        .classList.remove('card__container--active');
      this.disabled.emit(this.order);
    }
  }

  toggleDetails(event: any) {
    event.stopPropagation();
    const className = 'expand-details';
    const element = event.currentTarget;
    const cardContainer = element.closest('.card__container');
    const detailContainer = cardContainer.querySelector(
      '.card__details-container'
    );
    const hasClass = element.classList.contains(className);
    if (hasClass) {
      element.classList.remove(className);
      detailContainer.classList.add('card__details--hide');
      if (this.checked == false) {
        cardContainer.classList.remove('card__container--active');
      }
    } else {
      element.classList.add(className);
      detailContainer.classList.remove('card__details--hide');
      cardContainer.classList.add('card__container--active');
    }
  }
}
