import {
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApmErrorHandler,
  ApmModule,
  ApmService,
} from '@elastic/apm-rum-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpInterceptorService } from './core/services/interceptors/http-interceptor.service';
import { SharedModule } from './shared/shared.module';

/** Config locale pt-Br */
registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    ApmModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgxSpinnerModule,
    SharedModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    ApmService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(service: ApmService) {
    if (environment.production) {
      /*Agent API is exposed through this apm instance*/
      const apm = service.init({
        serviceName: 'temrango-web',
        serverUrl: environment.apmServer,
      });
    }
  }
}
