import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';

import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from 'src/app/modules/public/modules/login/services/login.service';
import { SessionStorageService } from '../session-storage.service';
import { ToastService } from '../toast-service.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  readonly empty = ' ';

  private login = '/login';
  private register = '/register';
  private steps = '/registers';
  private contact = '/home';

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private loginService: LoginService,
    private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /** Recuperando token do local storage */
    let token = this.sessionStorageService.getItem(SessionStorageKey.TOKEN);
    const type = this.sessionStorageService.getItem(
      SessionStorageKey.TOKEN_TYPE
    );

    /** Verificando se existe token no local storage */
    if (
      this.router.url !== this.login &&
      this.router.url !== this.register &&
      this.router.url !== this.steps &&
      this.router.url !== this.contact
    ) {
      if (token) {
        const jwtHelper = new JwtHelperService();
        /** 5 minutos em segundos */
        const TIME_IN_SECONDS = 300;

        /** Verificando se o accessToken expira em 5 minutos */
        if (jwtHelper.isTokenExpired(token, TIME_IN_SECONDS)) {
          /** Recuperando refreshToken do LocalStorage */
          const refreshToken = this.sessionStorageService.getItem(
            SessionStorageKey.REFRESH_TOKEN
          );

          this.loginService.sendRefreshToken(refreshToken).subscribe((data) => {
            this.sessionStorageService.storage(
              SessionStorageKey.TOKEN,
              data.access_token
            );
            this.sessionStorageService.storage(
              SessionStorageKey.REFRESH_TOKEN,
              data.refresh_token
            );
            this.sessionStorageService.storage(
              SessionStorageKey.EXPIRES_IN,
              data.expires_in
            );
          });

          token = this.sessionStorageService.getItem(SessionStorageKey.TOKEN);
        }

        if (!this.userService.isAuthenticated()) this.redirect(this.login);

        req = req.clone({
          setHeaders: {
            Authorization: `${type}${this.empty}${token}`,
          },
        });
      } else {
        this.redirect(this.login);
      }
    }
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          const message = err?.error?.msg;
        }
      )
    );
  }

  private redirect(url: string) {
    this.router.navigate([url]);

    this.toastService.showError(
      3000,
      'Seu acesso expirou!',
      'Faça login na aplicação para continuar.'
    );
  }
}
