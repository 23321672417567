import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import * as moment from 'moment';
moment.locale('pt-bt');

/** Configurando libs para criação e impressão do PDF do pedido */
const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;

import * as pdfMake from 'pdfmake/build/pdfmake';

import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';

import { OrderResponseApiModel } from '../models/order-response-api.model';
import { PrinterConfiguration } from '../../manage-settings/models/printer-configuration.model';

import {
  getKitchenTemplate,
  getMotoboyTemplate,
} from '../templates/print-templates';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, take, map } from 'rxjs/operators';
import { ResponseAPI } from 'src/app/core/model/response-api.model';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  static BASE_PATH: string = 'temrango-places/api/settings/printers';

  private printerConfig: PrinterConfiguration | null = null;

  constructor(
    private sessionStorageService: SessionStorageService,
    private httpClient: HttpClient
  ) {}

  /**
   * @description Recuperar configurações de impressão do usuário
   *
   * @author Darllinson Azevedo
   *
   * @returns Config. de impressão, caso não seja encontrada, retorna `null`
   */
  getPrinterConfig(): Observable<PrinterConfiguration> {
    return this.httpClient
      .get<ResponseAPI<PrinterConfiguration[]>>(
        `${environment.apiPlaces}/${PrintService.BASE_PATH}`
      )
      .pipe(
        map((response) => response.payload?.pop()),
        map((printerConfig: any) => {
          this.printerConfig = {
            id: printerConfig.id,
            width: printerConfig.width,
            deliveryman: printerConfig.deliveryman,
            status: printerConfig.status === 'ACTIVE',
          };

          return this.printerConfig;
        })
      );
  }

  /**
   * @description Salvar configuração de impressão de pedidos do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param config Payload dos dados do formulário
   */
  savePrinterConfig(config: PrinterConfiguration): void {
    if (!!!config.id) {
      this.httpClient
        .post<any>(`${environment.apiPlaces}/${PrintService.BASE_PATH}`, {
          width: config.width,
          deliveryman: config.deliveryman,
          status: config.status ? 'ACTIVE' : 'INACTIVE',
        })
        .pipe(take(1))
        .subscribe();
    } else {
      this.httpClient
        .put<any>(
          `${environment.apiPlaces}/${PrintService.BASE_PATH}/${config.id}`,
          {
            width: config.width,
            deliveryman: config.deliveryman,
            status: config.status ? 'ACTIVE' : 'INACTIVE',
          }
        )
        .pipe(take(1))
        .subscribe();
    }
  }

  /**
   * @description Imprimir comprovante do pedido do cliente
   *
   * @author Darllinson Azevedo
   *
   * @param order Payload com os dados do pedido
   */
  printOrder(order: OrderResponseApiModel, type: 'kitchen' | 'motoboy'): void {
    let template: any;

    switch (this.printerConfig.width) {
      case '58':
        template =
          type === 'kitchen'
            ? getKitchenTemplate('58', order)
            : getMotoboyTemplate('58', order);
        break;
      case '72':
        template =
          type === 'kitchen'
            ? getKitchenTemplate('72', order)
            : getMotoboyTemplate('72', order);
        break;
      case '80':
        template =
          type === 'kitchen'
            ? getKitchenTemplate('80', order)
            : getMotoboyTemplate('80', order);
        break;
    }

    pdfMake.createPdf(template).print();
  }

  /**
   * @description Salvar PDF com os dados do pedido
   *
   * @author Darllinson Azevedo
   *
   * @param order Payload com os dados do pedido
   */
  savePdf(order: OrderResponseApiModel): void {
    let template: any;

    switch (this.printerConfig.width) {
      case '58':
        template = getKitchenTemplate('58', order);
        break;
      case '72':
        template = template = getKitchenTemplate('72', order);
        break;
      case '80':
        template = template = getKitchenTemplate('80', order);
        break;
    }

    pdfMake
      .createPdf(template)
      .download(`pedido-${order.orderNumber}-temrango.pdf`);
  }
}
