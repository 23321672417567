import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';

interface ItemSelect {
  id: any,
  label: string
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @ViewChild('select') public select;
  @Output() public change = new EventEmitter();
  @Input() public values: Array<ItemSelect> = [{id: '', label: ''}];
  public randon = (Math.random() + 1).toString(36).substring(7)

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.select.nativeElement.removeAttribute("open");
  }
  clicked($event: any) {
    const element = $event.currentTarget as HTMLInputElement
    element.querySelector("label").click()
    this.change.emit(element.querySelector("label").attributes[1].value)
  }

}
