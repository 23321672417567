import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';
import { UserInfoModel } from 'src/app/shared/models/user-info.model';
import { environment } from 'src/environments/environment';

import { ResponseAPI } from '../model/response-api.model';
import { SessionStorageService } from './session-storage.service';
import { LoginService } from 'src/app/modules/public/modules/login/services/login.service';
import { PlaceAddressDTO } from 'src/app/modules/private/modules/dashboard/components/profile/models/place-address.model';
import { PlaceDTO } from 'src/app/modules/private/modules/dashboard/components/profile/models/place.model';
import { LegalResponsible } from 'src/app/modules/private/modules/dashboard/components/profile/models/legal-responsible.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** URI to hit temrango-users API */
  readonly pUsers = 'temrango-users/api';

  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private loginService: LoginService
  ) {}

  /**
   * @description Obter informações do usuário autenticado
   *
   * @author Alysson Cordeiro
   */
  getUserInfo(): Observable<UserInfoModel> {
    /** Recuperando token do LocalStorage */
    let accessToken = this.sessionStorageService.getItem(
      SessionStorageKey.TOKEN
    );

    if (environment.hasIam) {
      const jwt = jwt_decode(accessToken);
      const userInfoModel: UserInfoModel = {
        id: jwt['id'],
        name: jwt['name'],
        email: jwt['email'],
        roles: jwt['realm_access'].roles,
      };
      return of(userInfoModel);
    } else {
      return of(this.getDummyUserInfoModel());
    }
  }

  getDummyUserInfoModel(): UserInfoModel {
    const userInfoModel: UserInfoModel = {
      id: '1',
      name: 'Alysson Cordeiro',
      email: 'alysson.cordeiro@temrango.com',
      roles: ['dummy'],
    };
    return userInfoModel;
  }

  /**
   * @description Solicitar e-mail para recuperar password
   *
   * @author Alysson Cordeiro
   *
   * @param email E-mail cadastrado para recuperação
   */
  recoveryPassword(email: string): Observable<ResponseAPI> {
    return this.httpClient.post<any>(
      `${environment.apiUsers}/${this.pUsers}/recovery-password`,
      {
        email,
      }
    );
  }

  /**
   * @description Verificar se o token para recuperar senha é valido
   *
   * @author Darllinson Azevedo
   *
   * @param token Token enviado pelo usuário
   */
  verifyToken(token: string): Observable<ResponseAPI> {
    return this.httpClient.get<any>(
      `${environment.apiUsers}/${this.pUsers}/recovery-password/token/${token}`
    );
  }

  /**
   * @description Redefinir senha do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param password Nova senha do usuário
   * @param token Token enviado pelo usuário
   */
  resetPassword(password: string, token: string): Observable<ResponseAPI> {
    return this.httpClient.post<any>(
      `${environment.apiUsers}/${this.pUsers}/recovery-password/reset`,
      {
        password,
        token,
      }
    );
  }

  /**
   * @description Redefinir senha do usuário sem a necessidade de um token (método exclusivo para a área de perfil do lojista)
   *
   * @author Darllinson Azevedo
   *
   * @param password Nova senha do usuário
   */
  resetPasswordWithoutToken(password: string): Observable<ResponseAPI> {
    return this.httpClient.patch<any>(
      `${environment.apiUsers}/${this.pUsers}/users/password`,
      { password }
    );
  }

  /**
   * @description Verificar se o usuário está autenticado na aplicação
   *
   * @author Darllinson Azevedo
   *
   * @returns true se o usuário está autenticado
   */
  isAuthenticated(): boolean {
    const accessToken = this.sessionStorageService.getItem(
      SessionStorageKey.TOKEN
    );

    if (!accessToken) return false;

    const jwtHelper = new JwtHelperService();
    return !jwtHelper.isTokenExpired(accessToken);
  }

  /**
   * @description Recuperar estabelecimentos do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário autenticado
   */
  getEstablishments(userId: string) {
    return this.httpClient.get<ResponseAPI>(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/places`
    );
  }

  /**
   * @description Recuperar detalhes do estabelecimento do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário autenticado
   */
  getEstablishmentDetails(userId: string) {
    return this.httpClient.get<ResponseAPI>(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/places/details`
    );
  }

  /**
   * @description Recuperar endereços do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário
   * @returns Endereço principal do usuário
   */
  getUserAddress(userId: string): Observable<ResponseAPI> {
    return this.httpClient.get<ResponseAPI>(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/addresses`
    );
  }

  /**
   * @description Recuperar o responsável legal do negócio
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário autenticado
   * @returns Dados do responsável legal do negócio
   */
  getLegalResponsible(userId: string) {
    return this.httpClient.get<ResponseAPI>(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/legal-responsible`
    );
  }

  /**
   * @description Atualizar endereço do estabelecimento do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário
   * @param address Payload com o endereço atualizado
   */
  updateAddress(
    userId: string,
    address: PlaceAddressDTO
  ): Observable<ResponseAPI> {
    return this.httpClient.patch(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/addresses`,
      address
    );
  }

  /**
   * @description Atualizar um estabelecimento do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário
   * @param address Payload com os dados do estabelecimento atualizados
   */
  updatePlaceData(
    userId: string,
    placeData: PlaceDTO
  ): Observable<ResponseAPI> {
    return this.httpClient.patch(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/places`,
      placeData
    );
  }

  /**
   * @description Atualizar dados do responsável legal do negócio
   *
   * @author Darllinson Azevedo
   *
   * @param userId Id do usuário
   * @param legalResponsibleData Payload com os dados a serem atualizados
   */
  updateLegalResponsible(
    userId: string,
    legalResponsibleData: LegalResponsible
  ): Observable<ResponseAPI> {
    return this.httpClient.patch(
      `${environment.apiUsers}/${this.pUsers}/users/${userId}/legal-responsible`,
      legalResponsibleData
    );
  }
}
