<div
  [formGroup]="avaliableDaysForm"
  class="form-group col-md-12 avaliable-days"
>
  <label>
    Disponibilidade
    <span
      data-tooltip="Dias em que esse ítem ficará disponível para seus clientes comprarem."
      data-flow="top"
    >
      <svg
        class="svg__span"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0V0ZM8 14.694C6.67461 14.694 5.379 14.3009 4.27706 13.5644C3.17512 12.828 2.31637 11.7812 1.80944 10.5566C1.30251 9.33198 1.17018 7.98452 1.42918 6.68469C1.68819 5.38485 2.3269 4.19104 3.26451 3.25426C4.20213 2.31749 5.39651 1.67985 6.69658 1.42201C7.99665 1.16416 9.34398 1.2977 10.5681 1.80573C11.7923 2.31376 12.8383 3.17345 13.5738 4.27605C14.3093 5.37864 14.7012 6.67461 14.7 8C14.6999 8.87954 14.5264 9.75043 14.1896 10.5629C13.8528 11.3754 13.3592 12.1136 12.737 12.7352C12.1148 13.3568 11.3762 13.8498 10.5634 14.1859C9.75059 14.522 8.87954 14.6947 8 14.694Z"
          fill="#05CB95"
        />
        <path
          d="M7.7932 10.4749C7.54187 10.4721 7.29538 10.5441 7.0851 10.6818C6.87483 10.8195 6.71026 11.0167 6.61233 11.2482C6.5144 11.4797 6.48753 11.735 6.53515 11.9818C6.58277 12.2286 6.70273 12.4557 6.87975 12.6341C7.05677 12.8126 7.28287 12.9343 7.52929 12.9839C7.7757 13.0335 8.03129 13.0087 8.26355 12.9126C8.49581 12.8165 8.69425 12.6535 8.83361 12.4443C8.97298 12.2351 9.04698 11.9892 9.0462 11.7379C9.05518 11.5705 9.02899 11.4031 8.96933 11.2465C8.90967 11.0899 8.81786 10.9475 8.69982 10.8285C8.58177 10.7095 8.44011 10.6166 8.28396 10.5557C8.12781 10.4948 7.96063 10.4672 7.7932 10.4749Z"
          fill="#05CB95"
        />
        <path
          d="M7.98616 3.30007C7.17524 3.29158 6.37527 3.48762 5.66016 3.87007L6.13416 5.38907C6.54544 5.12457 7.02225 4.97983 7.51116 4.97107C8.21116 4.98607 8.52916 5.32007 8.52916 5.80707C8.42211 6.36843 8.1387 6.88096 7.72016 7.27007C7.44669 7.56018 7.23336 7.90155 7.09245 8.27451C6.95154 8.64746 6.88585 9.04461 6.89916 9.44307L6.91316 9.80507H8.76616V9.55407C8.76428 9.2313 8.82602 8.91133 8.94784 8.61243C9.06967 8.31353 9.24919 8.04156 9.47616 7.81207C10.1679 7.21948 10.5989 6.37866 10.6762 5.47107C10.6742 4.31607 9.82616 3.30007 7.98616 3.30007Z"
          fill="#05CB95"
        />
      </svg>
    </span>
  </label>
  <div class="form-check form-check-inline" *ngFor="let day of daysOfWeek">
    <input
      class="form-check-input"
      type="checkbox"
      id="{{ day }}"
      formControlName="{{ day }}"
    />
    <label class="form-check-label" for="{{ day }}">{{
      translateToPtBr(day)
    }}</label>
  </div>
</div>
