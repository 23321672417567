/**
 * @description Formatar número de telefone para o formato (XX) XXXXX-XXXX
 *
 * @author Darllinson Azevedo
 *
 * @param value Número para ser formatado
 * @returns Número de telefone no formato (XX) XXXXX-XXXX
 */
export function formatPhoneNumber(value: string): string {
  /** Formatando do número de telefone */
  const regex = /^(\d{2})(\d{5})(\d{4})$/;
  const phoneNumber = value.replace(regex, '($1) $2-$3');

  return phoneNumber;
}
