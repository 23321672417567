import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { PictureBase64Model } from './models/picture-base64.model';

@Component({
  selector: 'app-picture-upload',
  templateUrl: './picture-upload.component.html',
  styleUrls: ['./picture-upload.component.scss'],
})
export class PictureUploadComponent implements OnInit, OnChanges {
  /**File index*/
  readonly fIndex = 0;

  @ViewChild('input', { read: ElementRef }) fileInput: ElementRef;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  action: string;

  @Input()
  iconPlaceholder: string = 'icon-image-photo';

  @Input()
  iconAnexo: string = 'anexo-icon';

  @Input()
  customBox: boolean = false;

  @Input()
  customClass: string;

  @Input()
  text: string = 'selecione a imagem';

  @Input()
  extensions: string;

  /**Image preview*/
  @Input()
  preview;

  @Output()
  base64: EventEmitter<PictureBase64Model> =
    new EventEmitter<PictureBase64Model>();

  /**Value in megabytes */
  @Input() maxSize = 5;

  constructor(
    public domSanitizationService: DomSanitizer,
    public toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  /**
   * @description Exibir o preview da imagem e enviar sua representação base64 para o parent
   *
   * @author Alysson Cordeiro
   *
   * @param event - Upload da imagem
   */
  showPreview(event: any) {
    if (event.target.files && event.target.files[this.fIndex]) {
      const reader = new FileReader();
      const file = event.target.files[this.fIndex];
      if (file.size / 1024 / 1024 >= this.maxSize) {
        this.toastrService.error(
          `A imagem é maior que ${this.maxSize}MB`,
          'Erro!'
        );
        return;
      }
      reader.onload = (event: any) => (this.preview = event.target.result);
      reader.readAsDataURL(file);
      this.getBase64(file);
    }
  }

  /**
   * Obter a imagem na representação base64 e enviar para o parent(component)
   *
   * @author Alysson Cordeiro
   *
   * @param file Representação da imagem
   */
  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const pictureBase64: PictureBase64Model = {
        data: reader.result,
      };
      this.base64.emit(pictureBase64);
    };
  }

  click() {
    this.fileInput.nativeElement.value = '';
    const event = new MouseEvent('click', { bubbles: true });
    this.fileInput.nativeElement.dispatchEvent(event);
  }

  getSantizeUrl(url: string) {
    if (url) {
      return this.domSanitizationService.bypassSecurityTrustUrl(url);
    }
  }
}
