export enum OrderStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  CANCELLED_AUTOMATIC = 'CANCELLED_AUTOMATIC',
  CREATED = 'CREATED',
  DELIVERY = 'DELIVERY',
  NEW = 'NEW',
  ON_HOLD = 'ON_HOLD',
  OUT_DELIVERY = 'OUT_DELIVERY',
  PREPARING = 'PREPARING',
  READ_DELIVERY = 'READ_DELIVERY',
  RECEIVED = 'RECEIVED',
  RECUSED = 'RECUSE',
}
