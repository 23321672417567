<details #select class="app-select">
  <summary class="app-select__radios">
    <input
      type="radio"
      name="item{{ randon }}"
      id="default"
      title="Selecionar"
      checked
    />
    <input
      *ngFor="let item of values"
      type="radio"
      name="item{{ randon }}"
      [id]="item.id"
      [title]="item.label"
    />
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_786_469)">
        <path
          d="M7.42259 8.45957C7.28638 8.46028 7.15136 8.43416 7.02524 8.38268C6.89913 8.33121 6.78439 8.2554 6.68759 8.15957L0.303589 1.77457C0.201835 1.67924 0.120288 1.56443 0.0637959 1.43696C0.00730412 1.30949 -0.0229788 1.17196 -0.0252523 1.03255C-0.0275259 0.893137 -0.00174351 0.75469 0.0505617 0.625443C0.102867 0.496197 0.180627 0.378789 0.279218 0.280198C0.37781 0.181607 0.495219 0.103843 0.624466 0.0515375C0.753712 -0.000767704 0.892157 -0.0265493 1.03157 -0.0242758C1.17098 -0.0220023 1.30851 0.00828272 1.43598 0.0647745C1.56345 0.121266 1.67826 0.202813 1.77359 0.304566L7.42259 5.95357L13.0716 0.304566C13.2686 0.120006 13.5296 0.0192808 13.7996 0.0236826C14.0695 0.0280844 14.3271 0.137271 14.518 0.328156C14.7089 0.519042 14.8181 0.776669 14.8225 1.04659C14.8269 1.3165 14.7261 1.57756 14.5416 1.77457L8.15759 8.15957C8.06078 8.2554 7.94605 8.33121 7.81993 8.38268C7.69382 8.43416 7.5588 8.46028 7.42259 8.45957Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_786_469">
          <rect width="14.847" height="8.463" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </summary>
  <ul class="app-select__list" (click)="close()">
    <li
      *ngFor="let item of values"
      class="app-select__item"
      (click)="clicked($event)"
    >
      <label [for]="item.id">{{ item.label }}</label>
    </li>
  </ul>
</details>
