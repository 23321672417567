import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'mountOrderDate',
})
export class OrderDatePipe implements PipeTransform {
  transform(value: string, args: 'dd/mm/yyyy' | 'hh:mm'): string {
    moment.locale('pt-br');

    if (args === 'dd/mm/yyyy') {
      return moment(value).format('L');
    } else if (args === 'hh:mm') {
      return moment(value).format('LT');
    }
  }
}
