<div class="col-lg-12" [ngClass]="customClass">
  <div class="photo-area">
      <div class="header">
        <label class="mb-1">{{title}}</label>
       <div class="header__itens one" onclick="document.querySelectorAll('.one')[1].classList.add('visible'); document.querySelectorAll('.one')[1].style.display = 'flex'; document.querySelectorAll('.oone')[0].classList.add('visible');">
        <p>Dica</p>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.83 19.9C7.74995 19.8068 7.65212 19.7305 7.54223 19.6755C7.43234 19.6206 7.31259 19.5881 7.19 19.58H6.79C3.6 19.58 2 18.79 2 14.79V10.79C2 7.86 3.35 6.27 6.04 6.04C6.28 6.01 6.53 6 6.79 6H13.19C16.38 6 17.98 7.6 17.98 10.79Z" stroke="#05CB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.98 6.79V10.79C21.98 13.73 20.63 15.31 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.6 16.38 6 13.19 6H6.79004C6.53004 6 6.28004 6.01 6.04004 6.04C6.27004 3.35 7.86004 2 10.79 2H17.19C20.38 2 21.98 3.6 21.98 6.79V6.79Z" stroke="#05CB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.4951 13.25H13.5051M9.99512 13.25H10.0051M6.49512 13.25H6.50512" stroke="#05CB95" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
       </div>
       <div class="header__itens two" onclick="document.querySelectorAll('.two')[1].classList.add('visible'); document.querySelectorAll('.two')[1].style.display = 'flex';document.getElementsByClassName('ttwo')[0].classList.add('visible');">
        <p>Dica</p>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.83 19.9C7.74995 19.8068 7.65212 19.7305 7.54223 19.6755C7.43234 19.6206 7.31259 19.5881 7.19 19.58H6.79C3.6 19.58 2 18.79 2 14.79V10.79C2 7.86 3.35 6.27 6.04 6.04C6.28 6.01 6.53 6 6.79 6H13.19C16.38 6 17.98 7.6 17.98 10.79Z" stroke="#05CB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.98 6.79V10.79C21.98 13.73 20.63 15.31 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.6 16.38 6 13.19 6H6.79004C6.53004 6 6.28004 6.01 6.04004 6.04C6.27004 3.35 7.86004 2 10.79 2H17.19C20.38 2 21.98 3.6 21.98 6.79V6.79Z" stroke="#05CB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.4951 13.25H13.5051M9.99512 13.25H10.0051M6.49512 13.25H6.50512" stroke="#05CB95" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
       </div>
      </div>
      <p *ngIf="subtitle" class="subtitle">{{subtitle}}</p>
      <p>{{action}}</p>
      <div class="boxs-upload" [ngClass]="{'custom-box' : customBox}">
          <label class="box box-document-end"
               [ngClass]="{'border-0': preview}">
              <div class="wrap-input100">
                  <div class="empty-imagem" style="margin-rigth: 3px;"
                       [hidden]="preview">
                      <i><img src="assets/img/products/cadastrodeprodutosupload.png" alt=""></i>
                      <p>Arraste e solte ou</p>
                      <div class="select-img"
                            [ngClass]="customClass">
                          <span>{{text}}</span>
                      </div>
                  </div>
                  <img class="img-preview"
                       [src]="getSantizeUrl(preview)"
                       alt="imagem-preview"
                       [hidden]="!preview">
                  <div class="edit-img"
                       [hidden]="!preview">
                      <div class="box-icon">
                           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.794 4.55205L15.424 0.182054C15.366 0.124031 15.2972 0.0780004 15.2215 0.0465945C15.1457 0.0151886 15.0645 -0.000976563 14.9825 -0.000976562C14.9005 -0.000976562 14.8193 0.0151886 14.7435 0.0465945C14.6678 0.0780004 14.599 0.124031 14.541 0.182054L0.184 14.5401C0.125861 14.5979 0.0796861 14.6666 0.0481142 14.7422C0.0165424 14.8179 0.00019283 14.8991 0 14.9811L0 19.3521C0 19.5175 0.0657427 19.6763 0.182765 19.7933C0.299788 19.9103 0.458505 19.9761 0.624 19.9761H5C5.16545 19.9759 5.32407 19.9101 5.441 19.7931L19.794 5.43505C19.852 5.3771 19.8981 5.30828 19.9295 5.23252C19.9609 5.15677 19.977 5.07556 19.977 4.99355C19.977 4.91155 19.9609 4.83034 19.9295 4.75459C19.8981 4.67883 19.852 4.61001 19.794 4.55205ZM4.736 18.7281H1.247V15.2411L11.547 4.94105L15.034 8.42805L4.736 18.7281ZM15.919 7.54405L12.432 4.05705L14.983 1.50605L18.47 4.99405L15.919 7.54405Z" fill="white"/>
                            </svg>
                      </div>
                      <p>EDITAR FOTO</p>
                  </div>
                  <input type="file"
                         #input
                         class="form-control"
                         id="document-end"
                         aria-describedby="nome"
                         placeholder="Imagem"
                         (change)="showPreview($event)"
                         accept="{{extensions}}"
                         hidden>
              </div>
          </label>
      </div>
  </div>
</div>
