import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
})
export class PasswordValidatorComponent implements OnInit {
  @Input() public password: string = '';

  constructor() {}

  ngOnInit() {}

  /**
   * @description Verificar se a senha do usuário tem 8 ou mais caracteres
   *
   * @author Darllinson Azevedo
   */
  passwordHaveValidLength(): boolean {
    return this.password.length >= 8;
  }

  /**
   * @description Verificar se a senha do usuário tem letras minúsculas
   *
   * @author Darllinson Azevedo
   */
  passwordHaveLowerLetters(): boolean {
    const lowerLetter = /[a-z]/;
    return lowerLetter.test(this.password);
  }

  /**
   * @description Verificar se a senha do usuário tem letras maiúsculas
   *
   * @author Darllinson Azevedo
   */
  passwordHaveUpperLetters(): boolean {
    const upperLetter = /[A-Z]/;
    return upperLetter.test(this.password);
  }

  /**
   * @description Verificar se a senha do usuário tem números
   *
   * @author Darllinson Azevedo
   */
  passwordHaveNumbers(): boolean {
    const number = /[0-9]/;
    return number.test(this.password);
  }

  /**
   * @description Verificar se a senha do usuário tem caracteres especiais (@#!$%&)
   *
   * @author Darllinson Azevedo
   */
  passwordHaveSpecialCharacters(): boolean {
    const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    return specialChars.test(this.password);
  }
}
