import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-days-of-week',
  templateUrl: './checkbox-days-of-week.component.html',
  styleUrls: ['./checkbox-days-of-week.component.scss'],
})
export class CheckboxDaysOfWeekComponent implements OnInit {

  readonly monday = 'monday';
  readonly tuesday = 'tuesday';
  readonly wednesday = 'wednesday';
  readonly thursday = 'thursday';
  readonly friday = 'friday';
  readonly saturday = 'saturday';
  readonly sunday = 'sunday';

  readonly invalidDay = '-';

  @Input()
  avaliableDaysForm: FormGroup;

  daysOfWeek = [
    this.monday,
    this.tuesday,
    this.wednesday,
    this.thursday,
    this.friday,
    this.saturday,
    this.sunday,
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  /**
   * @description Obter controls do formulário
   *
   * @author Alysson Cordeiro
   */
  get getFormConstrols() {
    return this.avaliableDaysForm.controls;
  }

  /**
   * @description Obter a representação em PT-BR do dia da semana
   *
   * @author Alysson Cordeiro
   *
   * @param dayOfWeek - Dia da semana
   */
  translateToPtBr(dayOfWeek: string): string {
    let day: string;
    switch (dayOfWeek) {
      case this.monday: {
        day = 'Segunda';
        break;
      }
      case this.tuesday: {
        day = 'Terça';
        break;
      }
      case this.wednesday: {
        day = 'Quarta';
        break;
      }
      case this.thursday: {
        day = 'Quinta';
        break;
      }
      case this.friday: {
        day = 'Sexta';
        break;
      }
      case this.saturday: {
        day = 'Sábado';
        break;
      }
      case this.sunday: {
        day = 'Domingo';
        break;
      }
      default: {
        day = this.invalidDay;
        break;
      }
    }
    return day;
  }
}
