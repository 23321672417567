import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AccessToken } from '../models/access-token.model';
import { LoginModel } from '../models/login.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private loginUrl = '/zero1bit-iam/api/iam/login';
  private sendRefreshTokenUrl = 'zero1bit-iam/api/iam/refresh-token';

  constructor(
    private httpClient: HttpClient
  ) {}

  /**
   * @description Autenticar o usuário na aplicação
   *
   * @author Alysson Cordeiro
   *
   * @param userCredentials Interface com as credencias de acesso do usuário
   */
  login(userCredentials: LoginModel): Observable<any> {
    if (environment.hasIam) {
      return this.httpClient.post(
        `${environment.apiZero1BitIam}${this.loginUrl}`,
        userCredentials
      );
    } else {
      return of({
        access_token: 'aaaa',
        token_type: 'bbbb',
        refresh_token: 'ccc',
      });
    }
  }

  /**
   * @description Enviar refresh token
   *
   * @author Darllinson Azevedo
   *
   * @param refreshToken Refresh token (recuperado do local storage)
   */
  sendRefreshToken(refreshToken: string): Observable<AccessToken> {
    return this.httpClient.post<any>(
      `${environment.apiZero1BitIam}/${this.sendRefreshTokenUrl}`,
      { channel: 'TEMRANGO_WEB', refreshToken }
    );
  }
}
