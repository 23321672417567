import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mountOrderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: string, isDelivery: boolean): any {
    switch (value) {
      case 'ACCEPTED':
        return 'aprovado';
      case 'CANCELLED':
        return 'o cliente cancelou';
      case 'CANCELLED_AUTOMATIC':
        return 'a loja não aceitou';
      case 'CREATED':
        return 'criado';
      case 'DELIVERY':
        return isDelivery ? 'entregue' : 'retirado';
      case 'NEW':
        return 'recebido';
      case 'ON_HOLD':
        return 'em espera';
      case 'OUT_DELIVERY':
        return 'saiu para entrega';
      case 'PREPARING':
        return 'preparando';
      case 'READ_DELIVERY':
        return isDelivery ? 'pronto para entrega' : 'pronto para retirada';
      case 'RECEIVED':
        return 'enviado';
      case 'RECUSE':
        return 'a loja recusou';
    }
  }
}
