/**
 * @description Converter números para o formato monetário BRL (R$)
 *
 * @author Darllinson Azevedo
 *
 * @param value Valor a ser convertido
 * @returns Valor no formato monetário BRL
 */
export function convertToBrl(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}
