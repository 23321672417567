import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.jivoChat();
  }

  /**
   * @description Detectar evento de mudança na rota da aplicação
   *
   * @author Nathan Lira
   */
  routerChange() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.jivoChat();
      });
  }

  /**
   * @description Aplicar regra de exibição do widget do Jivo Chat
   *
   * @author Nathan Lira
   */
  jivoChat() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl: string = event.url;

        if (currentUrl !== '/private/dashboard/home') {
          document.getElementsByTagName('body')[0].classList.add('jvadd');
          if (currentUrl === '/private/dashboard')
            document.getElementsByTagName('body')[0].classList.remove('jvadd');
        } else
          document.getElementsByTagName('body')[0].classList.remove('jvadd');
      }
    });
  }

  title = 'temrango-client-web';
}
