<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#05cb95"
  type="pacman"
  [fullScreen]="true">
  <div class="animationLoading">
    <img
      src="assets/img/dashboard/loading.svg"
      alt=""
      srcset=""
      class="rotate-scale-up"/>
    <p>Aguarde...</p>
  </div>
</ngx-spinner>
