import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-mobile-popup-info',
  templateUrl: './mobile-popup-info.component.html',
  styleUrls: ['./mobile-popup-info.component.css']
})
export class MobilePopupInfoComponent implements OnInit {

  iconSrc;
  title;
  description;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
