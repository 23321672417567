<div class="container-register">
    <!-- Modal header -->
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <img src="assets/img/auth_establishment/close.svg " (click)="bsModalRef.hide()">
    </div>
    <!-- Modal body -->
    <div class="modal-body">
        <div class="boxs-upload">
            <div class="box box-document-end">
                <div class="wrap-input100 w-100">
                    <div class="empty-image" [hidden]="imagePreview">
                        <img src="assets/img/auth_establishment/cloud.svg">
                        <button class="btn" type="button" (click)="fileUploaderEnd.click()">buscar arquivo</button>
                        <small>Os formatos aceitos são: <span>PDF, PNG ou JPEG</span></small>
                    </div>
                    <img class="img-preview" [src]="getSantizeUrl(imagePreview)" alt="imagem-preview" [hidden]="!imagePreview">
                    <pdf-viewer [src]="imagePreview" [original-size]="false" [show-all]="false" style="display: block;">
                    </pdf-viewer>
                    <input type="file" #fileUploaderEnd class="form-control" id="document-end" aria-describedby="nome" placeholder="imagem" (change)="showPreviewImage($event)" accept="image/png, image/jpeg, application/pdf" hidden>
                </div>
                <div *ngIf="imagePreview" class="edit-image" (click)="fileUploaderEnd.click()">
                    <div class="icon">
                        <img src="assets/img/auth_establishment/edit.svg">
                    </div>
                    <span>trocar arquivo</span>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons" *ngIf="imagePreview">
        <button class="btn btn-save" (click)="save()">Salvar</button>
        <button class="btn btn-cancel" (click)="bsModalRef.hide()">Cancelar</button>
    </div>
</div>
