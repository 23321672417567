import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {

  imagePreview = null;
  selectFocusClass = false;
  submitted = false;

  title;

  @Input() uploadedImage;

  constructor(
    public _DomSanitizationService: DomSanitizer,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.uploadedImage ? this.imagePreview = this.uploadedImage : this.imagePreview = null;
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  getSantizeUrl(url: string) {
    if (url) {
      return this._DomSanitizationService.bypassSecurityTrustUrl(url);
    }
  }

  showPreviewImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => (this.imagePreview = e.target.result);
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.imagePreview = '';
    }
  }

  save(){
    this.bsModalRef.content.callback(this.imagePreview);
    this.bsModalRef.hide();
  }

}
