import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from 'src/app/modules/private/modules/dashboard/services/sidebar.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input()
  route: string;

  @Input()
  title: string;

  constructor( private router: Router,
               private sidebarService: SidebarService) { }

  ngOnInit(): void {

  }

  /**
   * @description Toggle sidebar
   *
   * @author Alysson Cordeiro
   */
  toggleSidebar(): void {
    this.sidebarService.toggleNavbar();
  }

  /**
   * @description Redirecionar para a route do @Input()
   *
   * @author Alysson Cordeiro
   */
  redirectToRouter(): void {
    this.router.navigate([this.route]);
  }

}
