import { OrderResponseApiModel } from '../models/order-response-api.model';

import { mount58mmKitchenTemplate, mount58mmMotoboyTemplate } from './mount-58mm-templates';
import { mount72mmKitchenTemplate, mount72mmMotoboyTemplate } from './mount-72mm-templates';
import { mount80mmKitchenTemplate, mount80mmMotoboyTemplate } from './mount-80mm-templates';

/**
 * @description Obter template para a cozinha
 *
 * @author Darllinson Azevedo
 *
 * @param paperSize Tamanho do papel da impressora
 * @param order Pedido para ser gerado o template
 */
export function getKitchenTemplate(
  paperSize: '58' | '72' | '80',
  order: OrderResponseApiModel
) {
  let template: any;

  switch (paperSize) {
    case '58':
      template = mount58mmKitchenTemplate(order);
      return template;
    case '72':
      template = mount72mmKitchenTemplate(order);
      return template;
    case '80':
      template = mount80mmKitchenTemplate(order);
      return template;
  }
}

/**
 * @description Obter template para o entregador
 *
 * @author Darllinson Azevedo
 *
 * @param paperSize Tamanho do papel da impressora
 * @param order Pedido para ser gerado o template
 */
export function getMotoboyTemplate(
  paperSize: '58' | '72' | '80',
  order: OrderResponseApiModel
) {
  let template: any;

  switch (paperSize) {
    case '58':
      template = mount58mmMotoboyTemplate(order);
      return template;
    case '72':
      template = mount72mmMotoboyTemplate(order);
      return template;
    case '80':
      template = mount80mmMotoboyTemplate(order);
      return template;
  }
}
