import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  toggled = true;
  retract: string;

  private toggleBehaviorSubject = new BehaviorSubject<string>('toggled');

  constructor() { }

  /**
   * @description Método para toggle o menu
   *
   * @author Evandro
   */
  toggleNavbar() {
    this.toggled = !this.toggled;
    if (this.toggled === false) {
      this.retract = '';
    } else {
      this.retract = 'toggled';
    }
    this.toggleBehaviorSubject.next(this.retract);
  }

  obterToggle() {
    return this.toggleBehaviorSubject;
  }
}
