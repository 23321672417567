import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from '../toast-service.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private userService: UserService,
    private router: Router,
    private toastService: ToastService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.userService.isAuthenticated()) {
      this.router.navigate(['login']);
      this.toastService.showError(
        3000,
        'Seu acesso expirou!',
        'Faça login na aplicação para continuar.'
      );
      return false;
    }
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.userService.isAuthenticated()) {
      this.router.navigate(['login']);
      this.toastService.showError(
        3000,
        'Seu acesso expirou!',
        'Faça login na aplicação para continuar.'
      );
      return false;
    }
    return true;
  }
}
